import axios from 'axios'
import {
    MessageBox, Message
} from 'element-ui'
import {getToken, removeToken} from "@/utils/auth";
import {SignUp, Login} from '@/components/index'
import Router from "@/router";


// let baseURL = 'http://139.196.79.97:9090'
// let baseURL = 'http://47.98.57.66:8080'
 let baseURL = process.env.VUE_APP_BASE_URL
// 线上环境
 //let baseURL = 'http://www.chinesa.org.cn'
// test 测试环境
//let baseURL = 'http://exam-test-user.lianbadj.com'
 //let baseURL = 'http://localhost:8081'

console.log('游戏职业技能认定平台:', baseURL)

// create an axios instance
const service = axios.create({
    // baseURL: baseURL,
    baseURL: process.env.NODE_ENV == 'production' ? null : baseURL,
    timeout: 20000, // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
    // 让每个请求携带自定义 token
    config.headers['TOKEN'] = getToken()
    return config
}, error => {
    Promise.reject(error)
})

let time
// response interceptor
service.interceptors.response.use(response => {
    if (response.headers['TOKEN']) {
        setToken(response.headers['TOKEN'])
    }
    try {
        const res = response.data
        if (/登录已过期/.test(res.msg)) {
            // if (/use/.test(Router.currentRoute.path)) {
            clearTimeout(time)
            time = setTimeout(() => {
                Message({
                    message: res.msg, type: "warning"
                });
            }, 300)
            removeToken()
            Router.push('/')
            // }
            // SignUp.install({isClose: true})
            // Login.install({isShow: true})
        }
    } catch (err) {
        console.log('request>>>错误', err)
        // Message.error('网络错误,请稍后重试.')
    }
    return response.data
}, error => {
    // // todo 自定义提示
    // if (error.message === 'cancel') {
    //     console.log('取消请求')
    //     Message.warning('请求太频繁')
    // } else {
    //     console.log('请求异常信息：' + error)
    Message({
        message: error, type: 'error',
    })
    //     return Promise.reject(error)
    // }
})

export default service
