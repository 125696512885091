<template>
	<div>
		<div class="header">
			<div class="header-box g-container bd">
				<!-- <div class="header-t"> -->
				<div class="header-logo">
					<a class="link" style="cursor: pointer" @click="goHome">
						<span>游戏职业技能认定平台</span>
						<span class="sub">中国通信工业协会电子竞技分会</span>
					</a>
				</div>
				<div class="nav">
					<a class="link link-cur" style="cursor: pointer" @click="goHome">首页</a>
					<span class="link link-arrow" @mouseenter="linkMouseOver" @mouseleave="linkMouseLeave">
						<span>职业技能</span>
						<span class="el-icon-arrow-down" style="margin-left:10px"></span>
						<div v-show="isShow_l" class="sub-nav">
							<a v-for="(item,index) in navList" :key="item" class="url"
								@click="goGamePlay(index)">{{ item }}</a>
						</div>
					</span>
					<a class="link" style="cursor: pointer" @click="showSearch">证书查询</a>
					<!--          <a class="link" style="cursor: pointer" @click="showNews">资讯动态</a>-->
					<a class="link" @click="showNews" style="cursor: pointer">公示公告</a>
					<span class="link link-arrow" @mouseenter="linkMouseOver2" @mouseleave="linkMouseLeave2">
						<span>综合服务</span>
						<span class="el-icon-arrow-down" style="margin-left:10px"></span>
						<div v-show="isShow_l2" class="sub-nav">
							<a v-for="(item,index) in navList2" :key="index" class="url"
								:href="item.url">{{ item.title }}</a>
						</div>
					</span>
					<span class="link link-arrow" @mouseenter="linkMouseOver3" @mouseleave="linkMouseLeave3">
						<span>产品服务</span>
						<span class="el-icon-arrow-down" style="margin-left:10px"></span>
						<div v-show="isShow_l3" class="sub-nav">
							<a v-for="(item,index) in navList3" :key="index" class="url"
								:href="item.url">{{ item.title }}</a>
						</div>
					</span>

					<a class="link" @click="showDo" style="cursor: pointer">联系我们</a>
				</div>
				<div class="user-account" style="cursor: pointer" @mouseenter="userMouserOver"
					@mouseleave="userMouseLeave">
					<div v-show="!isLogin" class="off-login btn-button btn-primary" @click="showLogin">登录/注册</div>
					<div v-show="isLogin" class="login">
						<div class="nick-name">
							<i class="el-icon-user"></i>
							&nbsp;{{ userName }}&nbsp;
						</div>
						<div v-show="isShow_b" class="sub-nav">
							<span v-for="(item,index) in userList" :key="item.value" v-loading="index===3 && loading"
								class="url" @click="goUserInfo(item)">{{ item.txt }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<MyDialog ref="MyDialog"></MyDialog>
	</div>
</template>

<script>
	import {
		getToken,
		getUserInfo
	} from "@/utils/auth";
	import {
		alipayCallback
	} from '@/api/alipay'
	import MyDialog from "@/layout/components/dialog";

	export default {
		components: {
			MyDialog
		},
		data() {
			return {
				navList2: [{
						title: '合作机构',
						url: '/#/jiaoyu/0'
					},
					{
						title: '培训资询',
						url: '/#/jiaoyu/1'
					},
					{
						title: '就业指导',
						url: '/#/jiaoyu/2'
					},
					{
						title: '赛事报名',
						url: '/#/jiaoyu/3'
					},
				],
				navList3: [{
						title: '产品主页',
						url: '/#/mall/0'
					},
					{
						title: '考试指南',
						url: '/#/mall/1'
					},

				],
				navList: [
					'电子竞技陪练师',
					'游戏陪玩师',
					"游戏语音主播",
					"游戏语音主持",
					"游戏音乐师",
					"游戏指导师",
					
				],
				userList: [{
						value: "0",
						txt: "我的考试"
					},
					{
						value: "1",
						txt: "我的证书"
					},
					{
						value: "2",
						txt: "实名认证"
					},
					// {
					//   value: "3",
					//   txt: "资料审核"
					// },
					{
						value: "4",
						txt: "我的订单"
					},
					{
						value: '7',
						txt: '上传履历'
					},
					{
						value: "5",
						txt: "修改密码"
					},
					{
						value: "6",
						txt: "退出登录"
					},
				],
				isShow_l3: false,
				isShow_l2: false,
				isShow_l: false,
				isShow_b: false,
				isLogin: false, // 是否登录
				scrollFlag: true,
				loading: false,
				userName: '请登录',
			}
		},
		watch: {
			'$route.path': {
				immediate: true,
				handler(newValue) {
					let res = getToken() == undefined || getToken() == null
					this.isLogin = !res
					if (/search/.test(newValue) || /news/.test(newValue) || /user/.test(newValue) || /certificate/.test(
							newValue) || /jiaoyu/.test(newValue)) {
						this.$nextTick(() => {
							let item = document.getElementsByClassName("header")[0];
							item.style.background = "rgb(8, 15, 43)";
							this.scrollFlag = false
						})
					} else {
						this.$nextTick(() => {
							let item = document.getElementsByClassName("header")[0];
							item.style.background = "rgb(8, 15, 43, 0.3)";
						})
					}
				}
			}
		},
		mounted() {
			let res = getToken() == undefined || getToken() == null
			if (!res) this.userName = getUserInfo().mobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
			window.addEventListener("scroll", this.throttling(this.setScroll, 100));
			if (this.$route.path.indexOf('out_trade_no=') != -1) {
				let tradeNo = this.$route.path.split('out_trade_no=')[1]
				let alipayNo = tradeNo.split('&method=alipay.trade.page.pay.return')[0]
				alipayCallback(alipayNo).then(res => {
					if (res) {
						this.$message({
							message: '报名成功',
							type: 'success'
						})
					}
				}).catch(err => {
					console.log('支付失败', err)
				})
			}
		},
		methods: {
			// 显示首页
			goHome() {
				this.$router.push('/')
			},
			// 显示了解详情
			goGamePlay(index) {
				this.$router.push(`/gamePlay/${index}`)
				this.__SignUp({
					occupation: this.num
				})
			},
			goGamePlay2(index) {
				this.$router.push(`/jiaoyu/${index}`)
			},

			// 显示咨询动态
			showNews() {
				this.$router.push('/zxdt/0')
				// window.scrollTo({
				//   top:10000,
				//   behavior:'smooth'
				// })
			},
			// 跳转联系我们
			showDo() {
				this.$router.push('/showDo')
			},
			// 显示证书查询
			showSearch() {
				// // 未登录不可查询证书
				// if (!getPhoneNum()) {
				//   this.showLogin()
				//   return
				// }
				// this.$router.push('/search')
				window.open('https://cert.chinaesa.org.cn/public/index.php')
			},
			goUserInfo(item) {
				if (item.value === "6") {
					this.unLogin();
					return;
				}
				this.$store.commit('setUserBtnActiveNum', item.value)
				this.$router.push(`/user/${item.value}`)
				// this.$refs["user"].show(item.value);
				// // this.isHome = false;
				// this.reset()
				// this.isUser = true
				// let headerBG = document.getElementsByClassName("header")[0];
				// headerBG.style.background = "rgb(8, 15, 43)";
				// this.headerBackground = "rgb(8, 15, 43)";
				// this.scrollFlag = false;
			},
			// 退出登录
			unLogin() {
				this.__Login({
					logOut: true
				})
				this.$router.push('/index')
			},
			// 显示登录界面
			showLogin() {
				this.__Login({
					isShow: true
				})
			},
			linkMouseOver2() {
				this.isShow_l2 = true;
			},
			linkMouseLeave2() {
				this.isShow_l2 = false;
			},
			linkMouseOver3() {
				this.isShow_l3 = true;
			},
			linkMouseLeave3() {
				this.isShow_l3 = false;
			},
			linkMouseOver() {
				this.isShow_l = true;
			},
			userMouserOver() {
				this.isShow_b = true;
			},
			linkMouseLeave() {
				this.isShow_l = false;
			},
			userMouseLeave() {
				this.isShow_b = false;
			},
			throttling(fn, wait, immediate) {
				let timer,
					timeStamp = 0;
				let context, args;
				let run = () => {
					timer = setTimeout(() => {
						if (!immediate) {
							fn.apply(context, args);
						}
						clearTimeout(timer);
						timer = null;
					}, wait);
				};
				return function() {
					context = this;
					args = arguments;
					if (timer) return;
					if (immediate) {
						fn.apply(context, args);
					}
					run();
				};
			},
			setScroll(e) {
				if (!this.scrollFlag) return;
				let scrollTop = e.srcElement.scrollingElement.scrollTop;
				if (scrollTop > 50) {
					let item = document.getElementsByClassName("header")[0];
					item.style.background = "rgb(8, 15, 43)";
				} else {
					let item = document.getElementsByClassName("header")[0];
					item.style.background = "rgba(8, 15, 43, 0.3)";
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.header {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		background: rgba(8, 15, 43, 0.3);
		transition: all 0s;
		z-index: 9;

		.header-box {
			width: 1080px;
			margin: 0 auto;
		}

		.g-container {
			display: flex;
			height: 100px;
			padding: 10px 0;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			width: 1080px;
			margin: 0 auto;
		}

		.header-logo {
			min-width: 340px;
			height: 80px;
			background: url("@/static/logo.png") no-repeat 0 50%;
			background-size: 80px 80px;
			font-size: 20px;
			line-height: 36px;
			font-weight: 700;

			a {
				display: block;
				padding: 10px 0 10px 100px;
				color: #fff;

				.sub {
					display: block;
					line-height: 24px;
					font-size: 12px;
					opacity: 0.5;
					font-weight: 400;
					text-indent: -70px;
					text-align: center;
				}
			}
		}

		.nav {
			display: flex;
			font-size: 14px;
			margin-left: auto;

			.link {
				margin-right: 25px;
				line-height: 50px;
				height: 50px;
				text-align: center;
				color: #fff;
			}

			.link:hover {
				color: #3960f4;
			}

			.link-arrow {
				position: relative;
				cursor: pointer;
				// background: url() no-repeat 100% 50%;
				background-size: 14px 14px;

				.sub-nav {
					position: absolute;
					left: 50%;
					top: 80%;
					margin-left: -9px;
					padding: 12px 30px;
					background: #fff;
					box-shadow: 0 4px 10px 0 rgba(14, 25, 47, 0.1);
					transform: translate(-50%, 10px);
					border-radius: 2px;

					.url {
						display: block;
						line-height: 20px;
						margin: 18px 0;
						color: #333;
						white-space: nowrap;
						cursor: pointer;
						text-decoration: none;
					}

					.url:hover {
						color: #3960f4;
					}
				}

				.sub-nav::before {
					content: "";
					position: absolute;
					left: 50%;
					top: -15px;
					border: 6px solid rgba(0, 0, 0, 0);
					border-bottom: 9px solid #fff;
					-webkit-transform: translate(-50%);
					-ms-transform: translate(-50%);
					transform: translate(-50%);
				}
			}
		}

		.user-account {
			position: relative;
			color: #fff;
			font-size: 14px;
			width: 110px;

			.off-login {
				width: 92px;
				line-height: 32px;
				color: #fff;
				font-size: 12px;
				text-align: center;
				border-radius: 4px;
			}

			.off-login::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
			}

			.nick-name {
				display: flex;
				align-items: center;
				font-size: 12px;
				line-height: 54px;

				// padding-left: 28px;
				// background: url("../static/user.png") no-repeat 0 50%;
				// background-size: 24px 24px;
				.el-icon-user {
					font-size: 18px;
					padding-right: 6px;
				}
			}

			.sub-nav {
				position: absolute;
				left: 50%;
				top: 80%;
				margin-left: -9px;
				padding: 12px 30px;
				background: #fff;
				box-shadow: 0 4px 10px 0 rgba(14, 25, 47, 0.1);
				transform: translate(-50%, 10px);
				border-radius: 2px;

				.url {
					display: block;
					line-height: 20px;
					margin: 18px 0;
					color: #333;
					white-space: nowrap;
					cursor: pointer;
					text-decoration: none;
				}

				.url:hover {
					color: #3960f4;
				}
			}

			.sub-nav::before {
				content: "";
				position: absolute;
				left: 50%;
				top: -15px;
				border: 6px solid rgba(0, 0, 0, 0);
				border-bottom: 9px solid #fff;
				-webkit-transform: translate(-50%);
				-ms-transform: translate(-50%);
				transform: translate(-50%);
			}
		}
	}
</style>
