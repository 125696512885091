const careerOptions = [{
        value: 0,
        label: "电子竞技陪练师",
        price: [280, 380, 580] // 初中高级价格
    },
    {
        value: 1,
        label: "游戏陪玩师",
        price: [280, 380, 580]
    },
    {
        value: 2,
        label: "游戏语音主播",
        price: [280, 380, 580]
    },
    {
        value: 3,
        label: "游戏语音主持",
        price: [280, 380, 580]
    },
    {
        value: 4,
        label: "游戏音乐师",
        price: [280, 380, 580]
    },
    {
        value: 5,
        label: "游戏指导师",
        price: [280, 380, 580]
    }
]
const skillOptions = [{
    label: 'MOBA',
    value: 0
}, {
    label: 'FPS',
    value: 1
}]

let levelOptions = [{
        label: '初级',
        value: 0
    },
    {
        label: '中级',
        value: 1
    },
    {
        label: '高级',
        value: 2
    },
]

let levelOptions1 = [{
            label: '初级',
            value: 0
        },
        {
            label: '中级',
            value: 1
        },
        {
            label: '高级',
            value: 2
        },
    ]
    // let levelOptions2 = [{
    //     label: '五级/初级',
    //     value: 0
    // }]

// let levelOptions2=[
//     {
//         label: '五级/初级',
//         value: 0
//     },
//     {
//         label: '四级/中级',
//         value: 1
//     },
//     {
//         label: '三级/高级',
//         value: 2
//     },
//     {
//         label: '二级/技师',
//         value: 3
//     },
//     {
//         label: '一级/高级技师',
//         value: 4
//     },
// ]

export default {
    data() {
        return {
            careerOptions,
            skillOptions,
            levelOptions,
            levelOptions1
        }
    }
}